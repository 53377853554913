// Types of action for authenticated user
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const UNSET_AUTH_USER = 'UNSET_AUTH_USER';
// Login action
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
// Get logged in user
export const REQUEST_LOGGED_IN_USER = 'REQUEST_LOGGED_IN_USER';
// Request tournament organizer token
export const REQUEST_TOURNAMENT_ORGANIZER_TOKEN = 'REQUEST_TOURNAMENT_ORGANIZER_TOKEN';
export const REQUEST_TOURNAMENT_ORGANIZER_TOKEN_SUCCESS = 'REQUEST_TOURNAMENT_ORGANIZER_TOKEN_SUCCESS';
// Request live leaderboard token
export const REQUEST_LIVE_LEADERBOARD_TOKEN = 'REQUEST_LIVE_LEADERBOARD_TOKEN';
export const REQUEST_LIVE_LEADERBOARD_TOKEN_SUCCESS = 'REQUEST_LIVE_LEADERBOARD_TOKEN_SUCCESS';