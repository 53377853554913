import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import { Provider } from 'react-redux';
import store from './redux/store';

let awsSettings;
if (process.env.NODE_ENV === 'production') {
  awsSettings = require('./cognito-config-prod');
} else {
  awsSettings = require('./cognito-config');
}

Amplify.configure(awsSettings.default);

ReactDOM.render(
  <Provider store={store.store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
