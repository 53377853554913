import { combineReducers } from 'redux';

import auth from './auth/reducer';
import tournaments from './tournaments/reducer';
import tournament from './tournament/reducer';
import tournamentFilters from './tournamentFilters/reducer';
import teamLeaderboard from './teamLeaderboard/reducer';
import playerLeaderboard from './playerLeaderboard/reducer';
import lastWinners from './lastWinners/reducer';
import headToHeadTeams from './headToHeadTeams/reducer';
import headToHeadPlayers from './headToHeadPlayers/reducer';
import exportAsImage from './exportAsImage/reducer';

export default combineReducers({
  auth: auth,
  tournaments: tournaments,
  tournament: tournament,
  tournamentFilters: tournamentFilters,
  teamLeaderboard: teamLeaderboard,
  playerLeaderboard: playerLeaderboard,
  lastWinners: lastWinners,
  headToHeadTeams: headToHeadTeams,
  headToHeadPlayers: headToHeadPlayers,
  exportAsImage: exportAsImage,
});