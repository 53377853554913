import { all, takeLatest, put } from 'redux-saga/effects';
import {  
  REQUEST_TOURNAMENTS,
  REQUEST_TOURNAMENTS_PENDING,
  REQUEST_TOURNAMENTS_SUCCESS,
  REQUEST_TOURNAMENTS_ERROR,
} from './types';
import { LOADING_ERROR } from '../../util/ErrorMessages';
import { tournamentAll } from '../../graphql/public/queries';
import { displayErrorNotification } from '../notification/actions';
import { publicQuery } from '../../graphql/requestHelper';

/**
 * Makes a request to server for all tournaments
 * 
 * @param {*} action 
 */
function* requestTournaments(action) {
  yield put({ type: REQUEST_TOURNAMENTS_PENDING });
  try {
    // Calling the server
    const result = yield publicQuery(tournamentAll, { game: action.payload });
    yield put({ type: REQUEST_TOURNAMENTS_SUCCESS, payload: result.data.tournamentAll });
  } catch (err) {
    yield put({ type: REQUEST_TOURNAMENTS_ERROR });
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_TOURNAMENTS, requestTournaments),
  ]);
}