import { all, takeLatest, put, select } from 'redux-saga/effects';
import {  
  REQUEST_TOURNAMENT,
  REQUEST_TOURNAMENT_PENDING,
  REQUEST_TOURNAMENT_SUCCESS,
  REQUEST_TOURNAMENT_ERROR,
  REQUEST_TOURNAMENT_MATCHES,
  REQUEST_TOURNAMENT_MATCHES_PENDING,
  REQUEST_TOURNAMENT_MATCHES_SUCCESS,
  REQUEST_TOURNAMENT_MATCHES_ERROR,
  REQUEST_TOURNAMENT_TEAMS,
  REQUEST_TOURNAMENT_TEAMS_PENDING,
  REQUEST_TOURNAMENT_TEAMS_SUCCESS,
  REQUEST_TOURNAMENT_TEAMS_ERROR,
  REQUEST_TOURNAMENT_PLAYERS,
  REQUEST_TOURNAMENT_PLAYERS_PENDING,
  REQUEST_TOURNAMENT_PLAYERS_SUCCESS,
  REQUEST_TOURNAMENT_PLAYERS_ERROR,
} from './types';
import { REQUEST_LIVE_LEADERBOARD_TOKEN } from '../auth/types';
import { LOADING_ERROR } from '../../util/ErrorMessages';
import { tournamentById, matchByTournament, teamNamesByTournament, playerNamesByTournament } from '../../graphql/public/queries';
import { displayErrorNotification } from '../notification/actions';
import { publicQuery } from '../../graphql/requestHelper';

/**
 * Makes a request to server for tournament by ID
 * 
 * @param {*} action 
 */
function* requestTournament(action) {
  yield put({ type: REQUEST_TOURNAMENT_PENDING });
  try {
    // Calling the server
    const result = yield publicQuery(tournamentById, action.payload);
    yield put({ type: REQUEST_TOURNAMENT_SUCCESS, payload: result.data.tournamentById });
    yield put({ type: REQUEST_TOURNAMENT_MATCHES, payload: { shardInfo: result.data.tournamentById.shard, page: 1, game: action.payload.game } });
    yield put({ type: REQUEST_LIVE_LEADERBOARD_TOKEN, payload: { shardInfo: result.data.tournamentById.shard } });
  } catch (err) {
    yield put({ type: REQUEST_TOURNAMENT_ERROR });
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

/**
 * Makes a request to server for tournament matches
 * 
 * @param {*} action 
 */
function* requestTournamentMatches(action) {
  yield put({ type: REQUEST_TOURNAMENT_MATCHES_PENDING });
  try {
    const token = yield select(state => state.auth.token);

    // Calling the server
    const result = yield publicQuery(matchByTournament, { 
        shardInfo: action.payload.shardInfo,
        page: action.payload.page,
        game: action.payload.game,
        token: token,
    });
    yield put({ type: REQUEST_TOURNAMENT_MATCHES_SUCCESS, payload: result.data.matchByTournament });
    yield put({ type: REQUEST_LIVE_LEADERBOARD_TOKEN, payload: { shardInfo: action.payload.shardInfo } });
  } catch (err) {
    yield put({ type: REQUEST_TOURNAMENT_MATCHES_ERROR });
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

/**
 * Makes a request to server for tournament teams
 * 
 * @param {*} action 
 */
function* requestTournamentTeams(action) {
  yield put({ type: REQUEST_TOURNAMENT_TEAMS_PENDING });
  try {
    // Calling the server
    const result = yield publicQuery(teamNamesByTournament, { tournament: action.payload.tournamentQuery, game: action.payload.game });
    yield put({ type: REQUEST_TOURNAMENT_TEAMS_SUCCESS, payload: {  tournament: action.payload.tournamentQuery, teams: result.data.teamNamesByTournament } });
  } catch (err) {
    yield put({ type: REQUEST_TOURNAMENT_TEAMS_ERROR });
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

/**
 * Makes a request to server for tournament players
 * 
 * @param {*} action 
 */
function* requestTournamentPlayers(action) {
  yield put({ type: REQUEST_TOURNAMENT_PLAYERS_PENDING });
  try {
    // Calling the server
    const result = yield publicQuery(playerNamesByTournament, { tournament: action.payload.tournamentQuery, game: action.payload.game });
    yield put({ type: REQUEST_TOURNAMENT_PLAYERS_SUCCESS, payload: { tournament: action.payload.tournamentQuery, players: result.data.playerNamesByTournament } });
  } catch (err) {
    yield put({ type: REQUEST_TOURNAMENT_PLAYERS_ERROR });
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_TOURNAMENT, requestTournament),
    takeLatest(REQUEST_TOURNAMENT_MATCHES, requestTournamentMatches),
    takeLatest(REQUEST_TOURNAMENT_TEAMS, requestTournamentTeams),
    takeLatest(REQUEST_TOURNAMENT_PLAYERS, requestTournamentPlayers),
  ]);
}