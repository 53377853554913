import { all, takeLatest, put } from 'redux-saga/effects';
import {  
  REQUEST_HEAD_TO_HEAD_TEAMS,
  REQUEST_HEAD_TO_HEAD_TEAMS_SUCCESS,
  REQUEST_HEAD_TO_HEAD_TEAMS_PENDING,
  REQUEST_HEAD_TO_HEAD_TEAMS_ERROR,
  REQUEST_HEAD_TO_HEAD_TEAMS_AS_CSV,
} from './types';
import {
  REQUEST_EXPORT_AS_IMAGE_PENDING,
  REQUEST_EXPORT_AS_IMAGE_SUCCESS,
} from '../exportAsImage/types';
import { headToHeadTeams } from '../../graphql/public/queries';
import { publicQuery } from '../../graphql/requestHelper';
import { convertImgToBase64URL } from '../../util/ExportAsImageUtil';
import { arrayToCsv, downloadCsv, getTeamLogo } from '../../util/CommonUtil';
import { PLAYER_STATISTICS } from '../../util/Constants';
import { displayErrorNotification } from '../notification/actions';
import { LOADING_ERROR } from '../../util/ErrorMessages';

/**
 * Makes a request to server for head to head teams
 * 
 * @param {*} action 
 */
function* requestHeadToHeadTeams(action) {
  yield put({ type: REQUEST_HEAD_TO_HEAD_TEAMS_PENDING });
  yield put({ type: REQUEST_EXPORT_AS_IMAGE_PENDING });
  try {
    // Calling the server
    const result = yield publicQuery(headToHeadTeams, {
      tournament: action.payload.tournament, 
      leftId: action.payload.leftTeamId, 
      rightId: action.payload.rightTeamId, 
      token: action.payload.token,
      game: action.payload.game,
    });

    if (action.payload.forExport) {
      result.data.headToHeadTeams.left.logo = yield convertImgToBase64URL(getTeamLogo(result.data.headToHeadTeams.left.name));
      result.data.headToHeadTeams.right.logo = yield convertImgToBase64URL(getTeamLogo(result.data.headToHeadTeams.right.name));
    }

    yield put({ type: REQUEST_HEAD_TO_HEAD_TEAMS_SUCCESS, payload: result.data.headToHeadTeams });
    yield put({ type: REQUEST_EXPORT_AS_IMAGE_SUCCESS });
  } catch (err) {
    yield put({ type: REQUEST_HEAD_TO_HEAD_TEAMS_ERROR });
  }
}

/**
 * Makes a request to server for head to head teams
 * 
 * @param {*} action 
 */
function* requestHeadToHeadTeamsAsCsv(action) {
  try {
    // Calling the server
    const result = (yield publicQuery(headToHeadTeams, {
      shardInfo: action.payload.tournament, 
      leftId: action.payload.leftTeamId, 
      rightId: action.payload.rightTeamId, 
      token: action.payload.token
    })).data.headToHeadTeams;
    roundStats(result.left);
    roundStats(result.right);
    result.left.teamLogo = getTeamLogo(result.left.name);
    result.right.teamLogo = getTeamLogo(result.right.name);
    const csv = arrayToCsv({
      name: 'Team Name',
      teamLogo: 'Team Logo',
      kills: PLAYER_STATISTICS.kills,
      avgPoints: 'Avg Points',
      avgRank: 'Avg Rank',
    }, [result.left, result.right]);
    downloadCsv(csv, `head-to-head-teams`);
  } catch (err) {
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_HEAD_TO_HEAD_TEAMS, requestHeadToHeadTeams),
    takeLatest(REQUEST_HEAD_TO_HEAD_TEAMS_AS_CSV, requestHeadToHeadTeamsAsCsv),
  ]);
}

/**
 * Rounds stats for better presentation
 * 
 * @param {*} leaderboard 
 */
function roundStats(team) {
  team.avgPoints = Math.round(team.avgPoints);
  team.avgRank = Math.round(team.avgRank);
}