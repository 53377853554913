// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "eu-west-1",
  "Auth": {
    "userPoolId": "eu-west-1_SMJkwP4IG",
    "userPoolWebClientId": "1hmv5lem9mce6hr8kic1kreluv",
    "cookieStorage": {
      "domain": ".twire.gg",
      "secure": false
    }
  }
};


export default awsmobile;

