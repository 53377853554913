import { 
  REQUEST_TOURNAMENT_PENDING,
  REQUEST_TOURNAMENT_SUCCESS,
  REQUEST_TOURNAMENT_ERROR,
  REQUEST_TOURNAMENT_MATCHES_SUCCESS,
  REQUEST_TOURNAMENT_MATCHES_PENDING,
  REQUEST_TOURNAMENT_MATCHES_ERROR,
  REQUEST_TOURNAMENT_TEAMS_SUCCESS,
  REQUEST_TOURNAMENT_PLAYERS_SUCCESS,
} from './types';

const INITIAL_STATE = {
  loaded: false,
  id: null,
  name: null,
  shard: null,
  filters: [],
  matches: [],
  teams: {},
  players: {},
  prodTournamentId: null,
  design: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request tournament pending or error
    case REQUEST_TOURNAMENT_PENDING:
    case REQUEST_TOURNAMENT_ERROR:
      return INITIAL_STATE;

    // On request tournament success
    case REQUEST_TOURNAMENT_SUCCESS:
      return {
        ...state,
        loaded: true,
        id: action.payload.id,
        name: action.payload.friendlyName,
        shard: action.payload.shard,
        filters: action.payload.tournamentFilters,
        prodTournamentId: action.payload.prodTournamentId,
        design: action.payload.overlayDesign
      };

    // On request meatches pending or error
    case REQUEST_TOURNAMENT_MATCHES_PENDING:
    case REQUEST_TOURNAMENT_MATCHES_ERROR:
      return {
        ...state,
        matches: [],
      };

    // On request matches success
    case REQUEST_TOURNAMENT_MATCHES_SUCCESS:
      return {
        ...state,
        matches: action.payload,
      }

    // On request teams success
    case REQUEST_TOURNAMENT_TEAMS_SUCCESS:
      const newTeams = { [action.payload.tournament]: action.payload.teams, ...state.teams };
      return {
        ...state,
        teams: newTeams,
      }

    // On request players success
    case REQUEST_TOURNAMENT_PLAYERS_SUCCESS:
      const newPlayers = { [action.payload.tournament]: action.payload.players, ...state.players };
      return {
        ...state,
        players: newPlayers,
      }

    default:
      return state;
  }
}

export default reducer;