import { all, takeLatest, put } from 'redux-saga/effects';
import {  
  REQUEST_PLAYER_LEADERBOARD,
  REQUEST_PLAYER_LEADERBOARD_PENDING,
  REQUEST_PLAYER_LEADERBOARD_SUCCESS,
  REQUEST_PLAYER_LEADERBOARD_ERROR,
  REQUEST_PLAYER_LEADERBOARD_AS_CSV,
} from './types';
import {
  REQUEST_EXPORT_AS_IMAGE_PENDING,
  REQUEST_EXPORT_AS_IMAGE_SUCCESS,
} from '../exportAsImage/types';
import { productionStats } from '../../graphql/public/queries';
import { publicQuery } from '../../graphql/requestHelper';
import { convertImgToBase64URL } from '../../util/ExportAsImageUtil';
import { arrayToCsv, downloadCsv, getPlayerImage, getTeamLogo } from '../../util/CommonUtil';
import { displayErrorNotification } from '../notification/actions';
import { LOADING_ERROR } from '../../util/ErrorMessages';

/**
 * Makes a request to server for player leaderboard
 * 
 * @param {*} action 
 */
function* requestPlayerLeaderboard(action) {
  yield put({ type: REQUEST_PLAYER_LEADERBOARD_PENDING });
  yield put({ type: REQUEST_EXPORT_AS_IMAGE_PENDING });
  try {
    // Calling the server
    let result = (yield publicQuery(productionStats, { tournament: action.payload.tournament, token: action.payload.token, game: action.payload.game })).data.productionStats;
    const sortBy = action.payload.sortBy;
    const leaderboard = result.leaderboard.sort((a, b) => a[sortBy] > b[sortBy] ? -1 : 1);
    for(let i = 0; i < action.payload.numOfPlayers; i++) {
      const player = leaderboard[i];
      roundStats(player);

      if (action.payload.forExport) {
        player.playerImage = yield convertImgToBase64URL(getPlayerImage(player.username), true);
        player.logo = yield convertImgToBase64URL(getTeamLogo(player.teamName));
      }
    }
    yield put({ type: REQUEST_PLAYER_LEADERBOARD_SUCCESS, payload: {
      leaderboard: leaderboard,
      matchName: result.matchName,
      lastMatchName: result.lastMatchName,
      groupName: result.groupName,
      overlayDesign: result.overlayDesign,
      overlayColor: result.overlayColor,
    } });
    yield put({ type: REQUEST_EXPORT_AS_IMAGE_SUCCESS });
  } catch (err) {
    yield put({ type: REQUEST_PLAYER_LEADERBOARD_ERROR });
  }
}

/**
 * Makes a request to server for player leaderboard
 * 
 * @param {*} action 
 */
function* requestPlayerLeaderboardAsCsv(action) {
  try {
    // Calling the server
    const result = (yield publicQuery(productionStats, { tournament: action.payload.tournament, token: action.payload.token, game: action.payload.game })).data.productionStats;
    const sortBy = action.payload.sortBy;
    const leaderboard = result.leaderboard.sort((a, b) => a[sortBy] > b[sortBy] ? -1 : 1);
    for(let i = 0; i < action.payload.numOfPlayers; i++) {
      const player = leaderboard[i];
      player.teamLogo = getTeamLogo(player.teamName);
      roundStats(player);
    }
    roundStats(leaderboard, action.payload.numOfPlayers);
    const csv = arrayToCsv({ username: 'Player Name', teamName: 'Team', kills: 'Kills', damageDealt: 'Damages', dbnos: 'Knocks', headshotKills: 'Headshot', longestKill: 'Longest Kill', teamLogo: 'Team Logo' }, leaderboard.slice(0, action.payload.numOfPlayers));
    downloadCsv(csv, `player-stats`);
  } catch (err) {
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_PLAYER_LEADERBOARD, requestPlayerLeaderboard),
    takeLatest(REQUEST_PLAYER_LEADERBOARD_AS_CSV, requestPlayerLeaderboardAsCsv),
  ]);
}

/**
 * Rounds stats for better presentation
 * 
 * @param {*} leaderboard 
 */
function roundStats(player) {
  player.damageDealt = parseInt(player.damageDealt);
  player.damageTaken = parseInt(player.damageTaken);
  player.swimDistance = parseFloat((player.swimDistance / 1000).toFixed(1));
  player.walkDistance = parseFloat((player.walkDistance / 1000).toFixed(1));
  player.rideDistance = parseFloat((player.rideDistance / 1000).toFixed(1));
}