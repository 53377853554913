// Types of action for requesting tournament
export const REQUEST_TOURNAMENT = 'REQUEST_TOURNAMENT';
export const REQUEST_TOURNAMENT_PENDING = 'REQUEST_TOURNAMENT_PENDING';
export const REQUEST_TOURNAMENT_SUCCESS = 'REQUEST_TOURNAMENT_SUCCESS';
export const REQUEST_TOURNAMENT_ERROR = 'REQUEST_TOURNAMENT_ERROR';
// Types of action for requesting tournament matches
export const REQUEST_TOURNAMENT_MATCHES = 'REQUEST_TOURNAMENT_MATCHES';
export const REQUEST_TOURNAMENT_MATCHES_PENDING = 'REQUEST_TOURNAMENT_MATCHES_PENDING';
export const REQUEST_TOURNAMENT_MATCHES_SUCCESS = 'REQUEST_TOURNAMENT_MATCHES_SUCCESS';
export const REQUEST_TOURNAMENT_MATCHES_ERROR = 'REQUEST_TOURNAMENT_MATCHES_ERROR';
// Types of action for requesting tournament teams
export const REQUEST_TOURNAMENT_TEAMS = 'REQUEST_TOURNAMENT_TEAMS';
export const REQUEST_TOURNAMENT_TEAMS_PENDING = 'REQUEST_TOURNAMENT_TEAMS_PENDING';
export const REQUEST_TOURNAMENT_TEAMS_SUCCESS = 'REQUEST_TOURNAMENT_TEAMS_SUCCESS';
export const REQUEST_TOURNAMENT_TEAMS_ERROR = 'REQUEST_TOURNAMENT_TEAMS_ERROR';
// Types of action for requesting tournament players
export const REQUEST_TOURNAMENT_PLAYERS = 'REQUEST_TOURNAMENT_PLAYERS';
export const REQUEST_TOURNAMENT_PLAYERS_PENDING = 'REQUEST_TOURNAMENT_PLAYERS_PENDING';
export const REQUEST_TOURNAMENT_PLAYERS_SUCCESS = 'REQUEST_TOURNAMENT_PLAYERS_SUCCESS';
export const REQUEST_TOURNAMENT_PLAYERS_ERROR = 'REQUEST_TOURNAMENT_PLAYERS_ERROR';