import { 
  REQUEST_TOURNAMENTS_PENDING,
  REQUEST_TOURNAMENTS_SUCCESS,
  REQUEST_TOURNAMENTS_ERROR,
} from './types';

const INITIAL_STATE = {
  loaded: false,
  tournaments: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request tournament pending or error
    case REQUEST_TOURNAMENTS_PENDING:
    case REQUEST_TOURNAMENTS_ERROR:
      return INITIAL_STATE;

    // On request tournament success
    case REQUEST_TOURNAMENTS_SUCCESS:
      return {
        loaded: true,
        tournaments: action.payload.filter(t => t.productionReady),
      };

    default:
      return state;
  }
}

export default reducer;