import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import {  
  REQUEST_LOGIN,
  REQUEST_LOGGED_IN_USER,
  REQUEST_TOURNAMENT_ORGANIZER_TOKEN,
  REQUEST_TOURNAMENT_ORGANIZER_TOKEN_SUCCESS,
  REQUEST_LIVE_LEADERBOARD_TOKEN,
  REQUEST_LIVE_LEADERBOARD_TOKEN_SUCCESS,
} from './types';
import { AUTH_LOGIN_ERROR } from '../../util/ErrorMessages';
import { setAuthUser, unsetAuthUser } from './actions';
import { displayErrorNotification } from '../notification/actions';
import { privateQuery } from '../../graphql/requestHelper';
import { tournamentOrganizerToken, liveLeaderboardToken } from '../../graphql/private/queries';

/**
 * Makes a request to server to login user
 * 
 * @param {*} action 
 */
function* requestLogin(action) {
  try {
    // Calling the server
    const user = yield call([Auth, 'signIn'], { username: action.payload.username, password: action.payload.password });
    // Triggering success action
    yield put(setAuthUser(user));
  } catch (err) {
    yield put(displayErrorNotification(AUTH_LOGIN_ERROR));
  }
}

/**
 * Gets logged in user if exists
 * 
 * @param {*} action 
 */
function* requestLoggedInUser(action) {
  try {
    // Calling the server
    const user = yield call([Auth, 'currentAuthenticatedUser']);
    // Triggering success action
    yield put(setAuthUser(user));
  } catch (err) {
    yield put(unsetAuthUser());
  }
}

/**
 * Gets tournament organizer token
 * 
 * @param {*} action 
 */
function* requestTournamentOrganizerToken(action) {
  try {
    // Calling the server
    const result = yield privateQuery(tournamentOrganizerToken);
    const token = result.data.tournamentOrganizerToken;
    // Triggering success action
    yield put({ type: REQUEST_TOURNAMENT_ORGANIZER_TOKEN_SUCCESS, payload: token });
  } catch (err) {
    
  }
}

/**
 * Gets live leaderboard token
 * 
 * @param {*} action 
 */
function* requestLiveLeaderboardToken(action) {
  try {
    // Calling the server
    const result = yield privateQuery(liveLeaderboardToken, { shardInfo: action.payload.shardInfo });
    const token = result.data.liveLeaderboardToken;
    // Triggering success action
    yield put({ type: REQUEST_LIVE_LEADERBOARD_TOKEN_SUCCESS, payload: token });
  } catch (err) {
    
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_LOGIN, requestLogin),
    takeLatest(REQUEST_LOGGED_IN_USER, requestLoggedInUser),
    takeLatest(REQUEST_TOURNAMENT_ORGANIZER_TOKEN, requestTournamentOrganizerToken),
    takeLatest(REQUEST_LIVE_LEADERBOARD_TOKEN, requestLiveLeaderboardToken),
  ]);
}