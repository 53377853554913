import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import history from './history'
// import { renderRoutes } from 'react-router-config';
import './App.scss'
import {
  HEAD_TO_HEAD_TEAM_TYPE,
  HEAD_TO_HEAD_PLAYER_TYPE,
} from './util/Constants'

const loading = () => <div className="animated fadeIn pt-3 text-center"></div>

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const Home = React.lazy(() => import('./views/Home/Home'))
const Login = React.lazy(() => import('./views/Pages/Login'))
const Page404 = React.lazy(() => import('./views/Pages/Page404'))
const Page500 = React.lazy(() => import('./views/Pages/Page500'))
const TeamLeaderboard = React.lazy(
  () => import('./views/TeamLeaderboard/TeamLeaderboard'),
)
const PlayerLeaderboard = React.lazy(
  () => import('./views/PlayerLeaderboard/PlayerLeaderboard'),
)
const SideLeaderboard = React.lazy(
  () => import('./views/SideLeaderboard/SideLeaderboard'),
)
const LastWinners = React.lazy(() => import('./views/LastWinners/LastWinners'))
const HeadToHeadTeams = React.lazy(
  () => import('./views/HeadToHeadTeams/HeadToHeadTeams'),
)
const HeadToHeadPlayers = React.lazy(
  () => import('./views/HeadToHeadPlayers/HeadToHeadPlayers'),
)
const CurrentMatch = React.lazy(
  () => import('./views/CurrentMatch/CurrentMatch'),
)
const Mvp = React.lazy(
  () => import('./views/Mvp/Mvp'),
)

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path="/team-leaderboard/:game/:params"
              name="Team Leaderboard"
              render={(props) => <TeamLeaderboard {...props} />}
            />
            <Route
              exact
              path="/player-leaderboard/:game/:params"
              name="Player Leaderboard"
              render={(props) => <PlayerLeaderboard {...props} />}
            />
            <Route
              exact
              path="/mvp/:game/:params"
              name="MVP"
              render={(props) => <Mvp {...props} />}
            />
            <Route
              exact
              path="/side-leaderboard/:game/:params"
              name="Side Leaderboard"
              render={(props) => <SideLeaderboard {...props} />}
            />
            <Route
              exact
              path="/last-winners/:game/:params"
              name="Last Winners"
              render={(props) => <LastWinners {...props} />}
            />
            <Route
              exact
              path={'/head-to-head-' + HEAD_TO_HEAD_TEAM_TYPE + '/:game/:params'}
              name="Head To Head Teams"
              render={(props) => <HeadToHeadTeams {...props} />}
            />
            <Route
              exact
              path={'/head-to-head-' + HEAD_TO_HEAD_PLAYER_TYPE + '/:game/:params'}
              name="Head To Head Players"
              render={(props) => <HeadToHeadPlayers {...props} />}
            />
            <Route
              exact
              path="/current-match/:game/:params"
              name="Current Match"
              render={(props) => <CurrentMatch {...props} />}
            />
            <Route exact path="/" name="Home" component={Home} />
            <PrivateRoute path="/app" name="Home" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </Router>
    )
  }
}

export default App
