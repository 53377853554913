import { 
  REQUEST_LAST_WINNERS_PENDING,
  REQUEST_LAST_WINNERS_SUCCESS,
  REQUEST_LAST_WINNERS_ERROR,
} from './types';

const INITIAL_STATE = {
  loaded: false,
  lastWinners: [],
  lastMatchNumber: null,
  overlayDesign: null,
  overlayColor: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request last winners pending or error
    case REQUEST_LAST_WINNERS_PENDING:
    case REQUEST_LAST_WINNERS_ERROR:
      return INITIAL_STATE;

    // On request last winners success
    case REQUEST_LAST_WINNERS_SUCCESS:
      return {
        loaded: true,
        lastWinners: action.payload.winners,
        lastMatchNumber: action.payload.lastMatchNumber,
        overlayDesign: action.payload.overlayDesign,
        overlayColor: JSON.parse(action.payload.overlayColor) || {},
      };

    default:
      return state;
  }
}

export default reducer;