import { TWIRE_ASSETS_URL } from "./Constants";

/**
 * Sets page title
 * 
 * @param {*} title 
 */
export const setPageTitle = (title) => {
  document.title = title;
}

/**
 * Converts number into placement string.
 * For example: 1 -> st
 * 
 * @param {*} n number to convert
 */
export const numberToPlacementSurfix = (n) => {
  if (n >= 10 && n <= 20) {
    return 'th';
  } else if (n % 10 === 1) {
    return 'st';
  } else if (n % 10 === 2) {
    return 'nd';
  } else if (n % 10 === 3) {
    return 'rd';
  } else {
    return 'th';
  }
}

/**
 * Returns url to the team logo picture
 * 
 * @param {*} teamName 
 */
export const getTeamLogo = (teamName) => {
  const game = window.location.pathname.includes('pubgc') ? 'pubgc' : 'pubg';
  return teamName ? `${TWIRE_ASSETS_URL}${game}/team-logos/${teamName.replaceAll(" ", "-").replace(/[^A-Za-z0-9\-_.]+/g, "").toLowerCase()}.png` : '';
}

/**
 * Returns url to the team logo picture
 * 
 * @param {*} playerName 
 */
export const getPlayerImage = (playerName) => {
  const game = window.location.pathname.includes('pubgc') ? 'pubgc' : 'pubg';
  return playerName ? `${TWIRE_ASSETS_URL}${game}/players/${playerName.toLowerCase()}.png` : '';
}

/**
 * Returns parameter value from URL if exists, else null
 * 
 * @param {*} param parameter name
 */
export const getQueryParamFromUrl = (param) => {
  return new URL(window.location.href).searchParams.get(param);
}

/**
 * Return csv
 * 
 * @param {*} columns map of array property to column title
 * @param {*} array data
 */
export const arrayToCsv = (columns, array) => {
  let csv = `${Object.keys(columns).map(k => columns[k]).join(',')}\r\n`;
  array.forEach(el => {
    csv += `${Object.keys(columns).map(k => el[k]).join(',')}\r\n`;
  });
  return csv;
}

/**
 * Downloads give csv with given name
 * 
 * @param {*} csv 
 * @param {*} name 
 */
export const downloadCsv = (csv, name) => {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  hiddenElement.target = '_blank';
  hiddenElement.download = `${name}.csv`;
  hiddenElement.click();
}

/**
 * Returns true if number is int
 */
export const isInt = (n) => {
  return n % 1 === 0;
}

/**
 * Converts number into displayable format
 * 
 * @param {*} n 
 */
export const numberForDisplay = (n) => {
  return isInt(n) ? n : parseFloat(n).toFixed(2);
}