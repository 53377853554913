import { AUTH_TOURNAMENT_ORGANIZER_GROUP } from "./Constants";

/**
 * Checks if user has permission to access the platform
 * 
 * @param {*} user 
 */
export const checkUserPermission = (user) => {
  if (!user.userGroups.includes(AUTH_TOURNAMENT_ORGANIZER_GROUP)) {
    throw new Error('Access denied!');
  }
}