// Twire assets URL
export const TWIRE_ASSETS_URL = "https://assets.twire.gg/";

// Auth constants
export const AUTH_TOURNAMENT_ORGANIZER_GROUP = 'TOURNAMENT_ORGANIZER';

// Tournament types
export const TOURNAMENT_TYPE_TOURNAMENT = 'tournament';
export const TOURNAMENT_TYPE_SCRIM = 'scrim';
export const TOURNAMENT_TYPES = {
  [TOURNAMENT_TYPE_TOURNAMENT]: TOURNAMENT_TYPE_TOURNAMENT.toUpperCase(),
  [TOURNAMENT_TYPE_SCRIM]: TOURNAMENT_TYPE_SCRIM.toUpperCase(),
};

// Tournament servers
export const TOURNAMENT_SERVER_EVENT = 'event';
export const TOURNAMENT_SERVER_LIVE = 'live';
export const TOURNAMENT_SERVERS = {
  [TOURNAMENT_SERVER_EVENT]: TOURNAMENT_SERVER_EVENT.toUpperCase(),
  [TOURNAMENT_SERVER_LIVE]: TOURNAMENT_SERVER_LIVE.toUpperCase(),
};

// Tournament regions
export const TOURNAMENT_REGION_GLOBAL = 'global';
export const TOURNAMENT_REGION_GLOBAL_NAME = 'Global';
export const TOURNAMENT_REGION_EUROPE = 'eu';
export const TOURNAMENT_REGION_EUROPE_NAME = 'Europe';
export const TOURNAMENT_REGION_NA = 'na';
export const TOURNAMENT_REGION_NA_NAME = 'North America';
export const TOURNAMENT_REGION_LA = 'la';
export const TOURNAMENT_REGION_LA_NAME = 'South America';
export const TOURNAMENT_REGION_ASIA = 'asia';
export const TOURNAMENT_REGION_ASIA_NAME = 'Asia';
export const TOURNAMENT_REGION_OCE = 'oce';
export const TOURNAMENT_REGION_OCE_NAME = 'Oceania';
export const TOURNAMENT_REGIONS = {
  [TOURNAMENT_REGION_GLOBAL]: TOURNAMENT_REGION_GLOBAL_NAME.toUpperCase(),
  [TOURNAMENT_REGION_EUROPE]: TOURNAMENT_REGION_EUROPE_NAME.toUpperCase(),
  [TOURNAMENT_REGION_NA]: TOURNAMENT_REGION_NA_NAME.toUpperCase(),
  [TOURNAMENT_REGION_LA]: TOURNAMENT_REGION_LA_NAME.toUpperCase(),
  [TOURNAMENT_REGION_ASIA]: TOURNAMENT_REGION_ASIA_NAME.toUpperCase(),
  [TOURNAMENT_REGION_OCE]: TOURNAMENT_REGION_OCE_NAME.toUpperCase(),
};

// Players statistics
export const PLAYER_STATISTICS = {
  kills: 'Kills',
  twr: 'TWR',
  assists: 'Assists',
  damageDealt: 'Damage Dealt',
  damageTaken: 'Damage Taken',
  dbnos: 'Knocks',
  revives: 'Revives',
  headshotKills: 'Headshot Kills',
  killSteals: 'Stolen Kills',
  killsStolenFrom: 'Lost Kills',
  swimDistance: 'Swim Distance',
  walkDistance: 'Walk Distance',
  rideDistance: 'Ride Distance',
  longestKill: 'Longest Kill',
  timeSurvived: 'Time Survived',
  killStreaks: 'Kill Streaks',
  heals: 'Heals',
  boosts: 'Boosts',
  vehicleDestroys: 'Vehicle Destroys',
}

// Players statistics texts
export const TOP_PLAYER_STATISTICS = {
  twr: 'Top 5 Players',
  kills: 'Top Kill Leaders',
  assists: 'Top Assisters',
  damageDealt: 'Top Damage Dealers',
  damageTaken: 'Most Damage Taken',
  dbnos: 'Top Knockers',
  revives: 'Top Revivers',
  headshotKills: 'Top Headshot Killers',
  killSteals: 'Top Kill Stealers',
  killsStolenFrom: 'Most Kills Stolen From Players',
  swimDistance: 'Top Swimmers',
  walkDistance: 'Top Walkers',
  rideDistance: 'Top Drivers',
  longestKill: 'Longest Kill Leaders',
  timeSurvived: 'Time Survived',
  killStreaks: 'Kill Streaks',
  heals: 'Heals',
  boosts: 'Boosts',
  vehicleDestroys: 'Vehicle Destroys',
}

// Head To Head constants
export const HEAD_TO_HEAD_TEAM_TYPE = 'teams';
export const HEAD_TO_HEAD_PLAYER_TYPE = 'players';

// Head to head details
export const HEAD_TO_HEAD_TEAM_DETAILS = {
  kills: 'Total Kills',
  damageDealt: 'Total Damage Dealt',
  avgRank: 'Average Placement',
  avgPoints: 'Average Points'
}
export const HEAD_TO_HEAD_PLAYER_DETAILS = {
  kills: 'Kills',
  damageDealt: 'Damage Dealt',
  assists: 'Assists',
  dbnos: 'Knocks'
}

export const LEADERBOARD_HEADER = {
  super20: [
    '#',
    'TEAM',
    'KILLS',
    'PTS',
  ],
  super22: [
    '#',
    'TEAM',
    'KILLS',
    'PTS',
  ],
  wwcd: [
    '#',
    'TEAM',
    'KILLS',
    'PTS',
  ],
  super21: [
    '#',
    'TEAM',
    'WINS',
    'KILLS',
  ],
  duo: [
    '#',
    'TEAM',
    'KILLS',
    'PTS',
  ],
}

export const LEADERBOARD_VALUES = {
  super20: [
    'kills',
    'totalPoints',
  ],
  super22: [
    'kills',
    'totalPoints',
  ],
  wwcd: [
    'kills',
    'totalPoints',
  ],
  super21: [
    'wins',
    'kills',
  ],
  duo: [
    'kills',
    'totalPoints',
  ],
}