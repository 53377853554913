// eslint-disable
// this is an auto generated file. This will be overwritten

export const matchByUsername = `query MatchByUsername($username: String!, $page: Int, $game: String!) {
  matchByUsername(username: $username, page: $page, game: $game) {
    isMember
    userMatches {
      id
      matchId
      telemetry
      duration
      gameMode
      shard
      map
      zoneProjection
      rank
      dbnos
      kills
      damageDealt
      gameCreatedAt
    }
  }
}
`;
export const matchByTournament = `query MatchByTournament(
  $shardInfo: String!
  $page: Int
  $game: String!
  $token: String
) {
  matchByTournament(
    shardInfo: $shardInfo
    page: $page
    game: $game
    token: $token
  ) {
    id
    matchId
    name
    telemetry
    duration
    gameMode
    shard
    shardInfo
    map
    zoneProjection
    gameCreatedAt
    tournamentName
    tournamentLogo
    zones
    teams {
      id
      name
      ranking
      previousPosition
      previousPositionChange
      slot
      rosterId
      logo
    }
  }
}
`;
export const matchById = `query MatchById($id: String!, $game: String!) {
  matchById(id: $id, game: $game) {
    id
    matchId
    name
    telemetry
    duration
    gameMode
    shard
    shardInfo
    map
    zoneProjection
    gameCreatedAt
    tournamentName
    tournamentLogo
    zones
    teams {
      id
      name
      ranking
      previousPosition
      previousPositionChange
      slot
      rosterId
      logo
    }
  }
}
`;
export const tournamentsByEventType = `query TournamentsByEventType($eventType: String!, $game: String!) {
  tournamentsByEventType(eventType: $eventType, game: $game) {
    id
    friendlyName
    shard
    eventType
    region
    imageName
    productionReady
    lastPlayedOn
    overlayDesign
    overlayColor
    prodTournamentId
    tier
    startDate
    stopDate
    prizePool
    currentlyActive
    tournamentSocials {
      id
      key
      type
      value
    }
    tournamentFilters {
      name
      value
      children {
        name
        value
      }
    }
  }
}
`;
export const tournamentInitialData = `query TournamentInitialData($id: Int!, $game: String!) {
  tournamentInitialData(id: $id, game: $game) {
    tournament {
      id
      friendlyName
      shard
      eventType
      region
      imageName
      productionReady
      lastPlayedOn
      overlayDesign
      overlayColor
      prodTournamentId
      tier
      startDate
      stopDate
      prizePool
      currentlyActive
      tournamentSocials {
        id
        key
        type
        value
      }
      tournamentFilters {
        name
        value
        children {
          name
          value
        }
      }
    }
    matches {
      id
      matchId
      name
      telemetry
      duration
      gameMode
      shard
      shardInfo
      map
      zoneProjection
      gameCreatedAt
      tournamentName
      tournamentLogo
      zones
      teams {
        id
        name
        ranking
        previousPosition
        previousPositionChange
        slot
        rosterId
        logo
      }
    }
    tournamentFilters {
      name
      value
      children {
        name
        value
      }
    }
  }
}
`;
export const tournamentAll = `query TournamentAll($game: String!) {
  tournamentAll(game: $game) {
    id
    friendlyName
    shard
    eventType
    region
    imageName
    productionReady
    lastPlayedOn
    overlayDesign
    overlayColor
    prodTournamentId
    tier
    startDate
    stopDate
    prizePool
    currentlyActive
    tournamentSocials {
      id
      key
      type
      value
    }
    tournamentFilters {
      name
      value
      children {
        name
        value
      }
    }
  }
}
`;
export const tournamentById = `query TournamentById($id: Int!, $game: String!) {
  tournamentById(id: $id, game: $game) {
    id
    friendlyName
    shard
    eventType
    region
    imageName
    productionReady
    lastPlayedOn
    overlayDesign
    overlayColor
    prodTournamentId
    tier
    startDate
    stopDate
    prizePool
    currentlyActive
    tournamentSocials {
      id
      key
      type
      value
    }
    tournamentFilters {
      name
      value
      children {
        name
        value
      }
    }
  }
}
`;
export const searchMatchPlayer = `query SearchMatchPlayer($matchId: Int!, $game: String!) {
  searchMatchPlayer(matchId: $matchId, game: $game) {
    id
    username
    rank
    dbnos
    assists
    damageDealt
    damageTaken
    revives
    kills
    killSteals
    killsStolenFrom
    rideDistance
    walkDistance
    timeSurvived
    killStreaks
    deathType
    heals
    boosts
    vehicleDestroys
    rosterId
  }
}
`;
export const teamByName = `query TeamByName($name: String!, $game: String!) {
  teamByName(name: $name, game: $game) {
    id
    name
    ranking
    previousPosition
    previousPositionChange
    slot
    rosterId
    logo
  }
}
`;
export const rotationByTeamId = `query RotationByTeamId($teamId: Int!, $game: String!) {
  rotationByTeamId(teamId: $teamId, game: $game) {
    id
    gameCreatedAt
    map
    zones
    zoneProjection
    movement
    landingSpot
    planePath
    rank
    kills
    damageDealt
    damageTaken
    team {
      id
      name
      ranking
      previousPosition
      previousPositionChange
      slot
      rosterId
      logo
    }
    matchName
    tournamentName
  }
}
`;
export const rotationByTeams = `query RotationByTeams($rotationRequest: RotationRequest!, $game: String!) {
  rotationByTeams(rotationRequest: $rotationRequest, game: $game) {
    id
    gameCreatedAt
    map
    zones
    zoneProjection
    movement
    landingSpot
    planePath
    rank
    kills
    damageDealt
    damageTaken
    team {
      id
      name
      ranking
      previousPosition
      previousPositionChange
      slot
      rosterId
      logo
    }
    matchName
    tournamentName
  }
}
`;
export const subscriptionPlans = `query SubscriptionPlans {
  subscriptionPlans {
    name
    price
    months
    provider
    planId
    region
  }
}
`;
export const tournamentFilters = `query TournamentFilters($tournamentId: Int!, $game: String!) {
  tournamentFilters(tournamentId: $tournamentId, game: $game) {
    name
    value
    children {
      name
      value
    }
  }
}
`;
export const teamRanking = `query TeamRanking($game: String!) {
  teamRanking(game: $game) {
    teams {
      id
      name
      ranking
      previousPosition
      previousPositionChange
      slot
      rosterId
      logo
    }
    updatedAt
  }
}
`;
export const productionLeaderboard = `query ProductionLeaderboard(
  $tournament: String!
  $token: String!
  $game: String!
) {
  productionLeaderboard(tournament: $tournament, token: $token, game: $game) {
    tournamentName
    groupName
    matchName
    lastMatchName
    leaderboard {
      team
      logo
      initial
      kills
      damageDealt
      points
      totalPoints
      wins
      bestMatchPoints
      bestKillPoints
      lastKills
      lastPoints
      numberOfMatches
      lastMatchPlayedAt
      previousPosition
      previousPositionChange
      players
    }
    overlayDesign
    overlayColor
    prodTournamentId
    numberOfMatches
    format
  }
}
`;
export const platformLeaderboard = `query PlatformLeaderboard(
  $tournament: String!
  $token: String
  $game: String!
) {
  platformLeaderboard(tournament: $tournament, token: $token, game: $game) {
    tournamentName
    groupName
    matchName
    lastMatchName
    leaderboard {
      team
      logo
      initial
      kills
      damageDealt
      points
      totalPoints
      wins
      bestMatchPoints
      bestKillPoints
      lastKills
      lastPoints
      numberOfMatches
      lastMatchPlayedAt
      previousPosition
      previousPositionChange
      players
    }
    overlayDesign
    overlayColor
    prodTournamentId
    numberOfMatches
    format
  }
}
`;
export const productionStats = `query ProductionStats($tournament: String!, $token: String!, $game: String!) {
  productionStats(tournament: $tournament, token: $token, game: $game) {
    tournamentName
    groupName
    matchName
    lastMatchName
    leaderboard {
      username
      teamName
      kills
      assists
      kd
      kda
      kas
      killsKnocks
      deaths
      diedFirst
      diedSecond
      diedThird
      diedForth
      damageDealt
      arDamage
      dmrDamage
      avgDamageDealt
      damageTaken
      avgDamageTaken
      dbnos
      knocked
      revives
      revived
      headshotKills
      killSteals
      killsStolenFrom
      swimDistance
      walkDistance
      rideDistance
      longestKill
      timeSurvived
      avgTimeSurvived
      killStreaks
      heals
      boosts
      vehicleDestroys
      healthRecovered
      grenadePickup
      grenadeDrop
      grenadeThrow
      grenadeDamage
      molotovPickup
      molotovDrop
      molotovThrow
      molotovDamage
      smokebombPickup
      smokebombDrop
      smokebombThrow
      flashbangPickup
      flashbangDrop
      flashbangThrow
      damageTakenFromBlueZone
      damageTakenFromEnemy
      damageDealtDamageTaken
      numOfMatches
      attacker
      finisher
      utility
      survivor
      teammate
      stealer
      twr
    }
    overlayDesign
    overlayColor
    prodTournamentId
  }
}
`;
export const platformStats = `query PlatformStats($tournament: String!, $token: String, $game: String!) {
  platformStats(tournament: $tournament, token: $token, game: $game) {
    tournamentName
    groupName
    matchName
    lastMatchName
    leaderboard {
      username
      teamName
      kills
      assists
      kd
      kda
      kas
      killsKnocks
      deaths
      diedFirst
      diedSecond
      diedThird
      diedForth
      damageDealt
      arDamage
      dmrDamage
      avgDamageDealt
      damageTaken
      avgDamageTaken
      dbnos
      knocked
      revives
      revived
      headshotKills
      killSteals
      killsStolenFrom
      swimDistance
      walkDistance
      rideDistance
      longestKill
      timeSurvived
      avgTimeSurvived
      killStreaks
      heals
      boosts
      vehicleDestroys
      healthRecovered
      grenadePickup
      grenadeDrop
      grenadeThrow
      grenadeDamage
      molotovPickup
      molotovDrop
      molotovThrow
      molotovDamage
      smokebombPickup
      smokebombDrop
      smokebombThrow
      flashbangPickup
      flashbangDrop
      flashbangThrow
      damageTakenFromBlueZone
      damageTakenFromEnemy
      damageDealtDamageTaken
      numOfMatches
      attacker
      finisher
      utility
      survivor
      teammate
      stealer
      twr
    }
    overlayDesign
    overlayColor
    prodTournamentId
  }
}
`;
export const teamStats = `query TeamStats($shardInfo: String!, $token: String, $game: String!) {
  teamStats(shardInfo: $shardInfo, token: $token, game: $game) {
    tournamentName
    groupName
    matchName
    lastMatchName
    teamStats {
      teamName
      stats {
        map
        rank
        kills
        assists
        damageDealt
        damageTaken
        dbnos
        revives
        headshotKills
        killSteals
        killsStolenFrom
        swimDistance
        walkDistance
        rideDistance
        timeSurvived
        killStreaks
        heals
        boosts
        vehicleDestroys
        healthRecovered
        grenadePickup
        grenadeDrop
        grenadeThrow
        grenadeDamage
        molotovPickup
        molotovDrop
        molotovThrow
        molotovDamage
        smokebombPickup
        smokebombDrop
        smokebombThrow
        flashbangPickup
        flashbangDrop
        flashbangThrow
        numOfMatches
        points
        wins
      }
    }
    overlayDesign
    overlayColor
    prodTournamentId
  }
}
`;
export const todaysWinners = `query TodaysWinners(
  $tournament: String!
  $numOfMatches: Int!
  $token: String
  $game: String!
) {
  todaysWinners(
    tournament: $tournament
    numOfMatches: $numOfMatches
    token: $token
    game: $game
  ) {
    tournamentName
    groupName
    lastMatchNumber
    winners {
      teamName
      matchNumber
      map
    }
    overlayDesign
    overlayColor
    prodTournamentId
  }
}
`;
export const currentMatch = `query CurrentMatch($tournament: String!, $game: String!) {
  currentMatch(tournament: $tournament, game: $game)
}
`;
export const teamNamesByTournament = `query TeamNamesByTournament($tournament: String!, $game: String!) {
  teamNamesByTournament(tournament: $tournament, game: $game) {
    id
    name
    ranking
    previousPosition
    previousPositionChange
    slot
    rosterId
    logo
  }
}
`;
export const playerNamesByTournament = `query PlayerNamesByTournament($tournament: String!, $game: String!) {
  playerNamesByTournament(tournament: $tournament, game: $game) {
    id
    username
  }
}
`;
export const headToHeadTeams = `query HeadToHeadTeams(
  $tournament: String!
  $leftId: Int!
  $rightId: Int!
  $token: String
  $game: String!
) {
  headToHeadTeams(
    tournament: $tournament
    leftId: $leftId
    rightId: $rightId
    token: $token
    game: $game
  ) {
    left {
      name
      kills
      damageDealt
      avgRank
      avgPoints
    }
    right {
      name
      kills
      damageDealt
      avgRank
      avgPoints
    }
    overlayDesign
    overlayColor
    prodTournamentId
  }
}
`;
export const headToHeadPlayers = `query HeadToHeadPlayers(
  $tournament: String!
  $leftId: Int!
  $rightId: Int!
  $token: String
  $game: String!
) {
  headToHeadPlayers(
    tournament: $tournament
    leftId: $leftId
    rightId: $rightId
    token: $token
    game: $game
  ) {
    left {
      username
      teamName
      kills
      damageDealt
      assists
      dbnos
    }
    right {
      username
      teamName
      kills
      damageDealt
      assists
      dbnos
    }
    overlayDesign
    overlayColor
    prodTournamentId
  }
}
`;
export const getArticles = `query GetArticles($game: String!, $page: Int) {
  getArticles(game: $game, page: $page) {
    author
    comments {
      content
      id
      insertedAt
      parentId
      updatedAt
      username
    }
    content
    id
    insertedAt
    summary
    summaryImg
    title
    updatedAt
    numberOfComments
  }
}
`;
export const getArticle = `query GetArticle($game: String!, $articleId: Int!) {
  getArticle(game: $game, articleId: $articleId) {
    author
    comments {
      content
      id
      insertedAt
      parentId
      updatedAt
      username
    }
    content
    id
    insertedAt
    summary
    summaryImg
    title
    updatedAt
    numberOfComments
  }
}
`;
export const getTournaments = `query GetTournaments(
  $game: String!
  $type: String!
  $timeType: String
  $tier: String
  $region: String
  $page: Int
) {
  getTournaments(
    game: $game
    type: $type
    timeType: $timeType
    tier: $tier
    region: $region
    page: $page
  ) {
    id
    friendlyName
    shard
    eventType
    region
    imageName
    productionReady
    lastPlayedOn
    overlayDesign
    overlayColor
    prodTournamentId
    tier
    startDate
    stopDate
    prizePool
    currentlyActive
    tournamentSocials {
      id
      key
      type
      value
    }
    tournamentFilters {
      name
      value
      children {
        name
        value
      }
    }
  }
}
`;
export const getLastestMatches = `query GetLastestMatches($game: String!, $tournamentId: Int, $page: Int) {
  getLastestMatches(game: $game, tournamentId: $tournamentId, page: $page) {
    id
    matchId
    name
    telemetry
    duration
    gameMode
    shard
    shardInfo
    map
    zoneProjection
    gameCreatedAt
    tournamentName
    tournamentLogo
    zones
    teams {
      id
      name
      ranking
      previousPosition
      previousPositionChange
      slot
      rosterId
      logo
    }
  }
}
`;
export const getFeatured = `query GetFeatured($game: String!) {
  getFeatured(game: $game) {
    type
    data
  }
}
`;
