import { all, fork } from 'redux-saga/effects';

import auth from './auth/saga';
import notification from './notification/saga';
import tournaments from './tournaments/saga';
import tournament from './tournament/saga';
import tournamentFilters from './tournamentFilters/saga';
import teamLeaderboard from './teamLeaderboard/saga';
import playerLeaderboard from './playerLeaderboard/saga';
import lastWinners from './lastWinners/saga';
import headToHeadTeams from './headToHeadTeams/saga';
import headToHeadPlayers from './headToHeadPlayers/saga';

// The root saga
function* rootSaga() {
    // Add all active sagas here to combine them
    yield all([
        fork(auth),
        fork(notification),
        fork(tournaments),
        fork(tournament),
        fork(tournamentFilters),
        fork(teamLeaderboard),
        fork(playerLeaderboard),
        fork(lastWinners),
        fork(headToHeadTeams),
        fork(headToHeadPlayers),
    ]);
}

export default rootSaga;