import { 
  SET_AUTH_USER,
  UNSET_AUTH_USER,
  REQUEST_TOURNAMENT_ORGANIZER_TOKEN_SUCCESS,
  REQUEST_LIVE_LEADERBOARD_TOKEN_SUCCESS,
} from './types';
import { checkUserPermission } from '../../util/AuthUtil';

const INITIAL_STATE = {
  loaded: false,
  username: null,
  sub: null,
  userGroups: [],
  token: null,
  liveLeaderboardToken: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On set authenticated user
    case SET_AUTH_USER:
      checkUserPermission(action.payload);
      return {
        ...state,
        loaded: true,
        username: action.payload.username,
        sub: action.payload.sub,
        userGroups: action.payload.userGroups,
      };

    // On remove authenticated user
    case UNSET_AUTH_USER:
      return {
        loaded: true,
        username: null,
        sub: null,
        userGroups: [],
        token: null,
      };

    // On tournament organizer token success
    case REQUEST_TOURNAMENT_ORGANIZER_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload,
      };

    // On tournament organizer token success
    case REQUEST_LIVE_LEADERBOARD_TOKEN_SUCCESS:
      return {
        ...state,
        liveLeaderboardToken: action.payload,
      };

    default:
      return state;
  }
}

export default reducer;