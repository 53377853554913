import { all, takeLatest, put } from 'redux-saga/effects';
import {  
  REQUEST_HEAD_TO_HEAD_PLAYERS,
  REQUEST_HEAD_TO_HEAD_PLAYERS_SUCCESS,
  REQUEST_HEAD_TO_HEAD_PLAYERS_PENDING,
  REQUEST_HEAD_TO_HEAD_PLAYERS_ERROR,
  REQUEST_HEAD_TO_HEAD_PLAYERS_AS_CSV,
} from './types';
import {
  REQUEST_EXPORT_AS_IMAGE_PENDING,
  REQUEST_EXPORT_AS_IMAGE_SUCCESS,
} from '../exportAsImage/types';
import { headToHeadPlayers } from '../../graphql/public/queries';
import { publicQuery } from '../../graphql/requestHelper';
import { convertImgToBase64URL } from '../../util/ExportAsImageUtil';
import { arrayToCsv, downloadCsv, getTeamLogo } from '../../util/CommonUtil';
import { PLAYER_STATISTICS } from '../../util/Constants';
import { displayErrorNotification } from '../notification/actions';
import { LOADING_ERROR } from '../../util/ErrorMessages';

/**
 * Makes a request to server for head to head players
 * 
 * @param {*} action 
 */
function* requestHeadToHeadPlayers(action) {
  yield put({ type: REQUEST_HEAD_TO_HEAD_PLAYERS_PENDING });
  yield put({ type: REQUEST_EXPORT_AS_IMAGE_PENDING });
  try {
    // Calling the server
    console.log(action.payload)
    const result = yield publicQuery(headToHeadPlayers, {
      tournament: action.payload.tournament, 
      leftId: action.payload.leftPlayerId, 
      rightId: action.payload.rightPlayerId, 
      token: action.payload.token,
      game: action.payload.game,
    });

    if (action.payload.forExport) {
      result.data.headToHeadPlayers.left.logo = yield convertImgToBase64URL(getTeamLogo(result.data.headToHeadPlayers.left.teamName));
      result.data.headToHeadPlayers.right.logo = yield convertImgToBase64URL(getTeamLogo(result.data.headToHeadPlayers.right.teamName));
    }

    yield put({ type: REQUEST_HEAD_TO_HEAD_PLAYERS_SUCCESS, payload: result.data.headToHeadPlayers });
    yield put({ type: REQUEST_EXPORT_AS_IMAGE_SUCCESS });
  } catch (err) {
    yield put({ type: REQUEST_HEAD_TO_HEAD_PLAYERS_ERROR });
  }
}

/**
 * Makes a request to server for head to head players
 * 
 * @param {*} action 
 */
function* requestHeadToHeadPlayersAsCsv(action) {
  try {
    // Calling the server
    const result = (yield publicQuery(headToHeadPlayers, {
      tournament: action.payload.tournament, 
      leftId: action.payload.leftPlayerId, 
      rightId: action.payload.rightPlayerId, 
      token: action.payload.token,
      game: action.payload.game,
    })).data.headToHeadPlayers;
    roundStats(result.left);
    roundStats(result.right);
    result.left.teamLogo = getTeamLogo(result.left.teamName);
    result.right.teamLogo = getTeamLogo(result.right.teamName);
    const csv = arrayToCsv({
      username: 'Player Name',
      teamName: 'Team Name',
      teamLogo: 'Team Logo',
      kills: PLAYER_STATISTICS.kills,
      assists: PLAYER_STATISTICS.assists,
      damageDealt: PLAYER_STATISTICS.damageDealt,
      dbnos: PLAYER_STATISTICS.dbnos,
    }, [result.left, result.right]);
    downloadCsv(csv, `head-to-head-players`);
  } catch (err) {
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_HEAD_TO_HEAD_PLAYERS, requestHeadToHeadPlayers),
    takeLatest(REQUEST_HEAD_TO_HEAD_PLAYERS_AS_CSV, requestHeadToHeadPlayersAsCsv),
  ]);
}

/**
 * Rounds stats for better presentation
 * 
 * @param {*} leaderboard 
 */
function roundStats(player) {
  player.damageDealt = Math.round(player.damageDealt);
}